'use client'
import React, { useRef } from 'react'
import { useScroll, useTransform, motion } from 'framer-motion' 
// import Banner from  "../assets/banner.png";
import Banner from  "../assets/home-banner.png";

export function HeroScrollDemo() {
    return (
<ContainerScroll
            titleComponent={
              <div className="flex items-center flex-col"> 

                <h1 className="text-5xl md:text-7xl leading-loose bg-clip-text text-gray-900 font-sans font-bold">
                Build & Ship <span className='text-darkblue'>APIs</span> on <span className='text-darkblue'>Data Instantly</span> with AI
                </h1> 
                <span class="mt-6 block text-3xl font-semibold text-gray-600">
          Build In Days. Scale For Years
          </span>
          <br />
                <button
                  size={"md"}
                  className="p-5 mb-6 md:mb-0 text-xl w-full sm:w-fit border-t-2 rounded-full bg-green hover:bg-green group transition-all flex items-center justify-center gap-4 hover:shadow-md hover:shadow-gray-500 duration-500"
                >
                  <span className="bg-clip-text text-gray-50 bg-gradient-to-r from-neutral-500 to-neutral-600  md:text-center font-sans group-hover:bg-gradient-to-r group-hover:from-black group-hover:to-black">
                    Start For Free Today
                  </span>
                </button>
                <br />
                <br />
                <br />
              </div>
            }
          />
        );
    }
    
export const ContainerScroll = ({
  titleComponent,
}) => {
  const containerRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
  })
  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  const scaleDimensions = () => {
    return isMobile ? [0.7, 0.9] : [1.05, 1]
  }

  const rotate = useTransform(scrollYProgress, [0, 1], [20, 0])
  const scale = useTransform(scrollYProgress, [0, 1], scaleDimensions())
  const translate = useTransform(scrollYProgress, [0, 1], [0, -100])

  return (
    <div
      className="h-full flex items-center justify-center relative p-20"
      ref={containerRef}
    >
      <div
        className="py-20 w-full relative"
        style={{
          perspective: '1000px',
        }}
      >
        <Header
          translate={translate}
          titleComponent={titleComponent}
        />
        <Card
          rotate={rotate}
          translate={translate}
          scale={scale}
        />
      </div>
    </div>
  )
}

export const Header = ({ translate, titleComponent }) => {
  return (
    <div
      style={{
        translateY: translate,
      }}
      className="div max-w-5xl mx-auto text-center"
    >
      {titleComponent}
    </div>
  )
}

export const Card = ({
  rotate,
  scale,
  translate,
} ) => {
  return (
    <motion.div
      style={{
        // rotateX: rotate, // rotate in X-axis
        // scale,
        // boxShadow:  '0 0 #0000004d, 0 9px 20px #0000004a, 0 37px 37px #00000042, 0 84px 50px #00000026, 0 49px 60px #0000000a, 0 233px 65px #00000003',
      }}
      className="max-w-9xl -mt-12 mx-auto h-full w-full  p-2 rounded-3xl"
    >
      <div className="bg-white h-full w-full rounded-2xl  gap-4 overflow-hidden transition-all ">
        <img
          src={Banner}
          fill
          alt="bannerImage"
          className="object-cover rounded-2xl"
        />
      </div>
    </motion.div>
  )
}
