import { HeroScrollDemo } from "../components/container-scroll-animation"; 
import { Modules, Auth,  Innovation, Comparison, Steps, GraphQL, MagicAI, HomeBanner, ShortCut } from "../components/content2";
import { DemoVideo } from "../components/demovideo"; 
import Help from "../components/help";  
import Partner from "../components/partners"; 
import { StepFeatures } from "../components/stepfeatures"; 
import  TextBlock  from "../components/textblock";

const HomePage = () => {
    return (
        <>  
        <HeroScrollDemo />
       {/* <Header />   */}
       {/* <Hero /> */}
       <TextBlock /> 
       <Steps /> 
       <StepFeatures />
       <Help />
       <ShortCut />
       <Comparison />
       {/* <Auth /> 
       <Innovation /> 
       <GraphQL />
       <MagicAI />  */}
       <Partner />
       {/* <HeroParallaxDemo /> */}
       <Modules /> 
       <DemoVideo />
        </>
    );
}

export default HomePage;